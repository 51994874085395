// variable

// font weight
$font-weight: (
    thin: 100,
    extra-light: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    bold: 700,
    extra-bold: 800,
    black: 900
);

// font size
$fs: (
    fs-default: 14px,
    fs-heading-large: 30px,
    fs-heading-normal: 20px,
    fs-heading-small: 18px,
    fs-label: 16px,
    fs-input: 13px,
    fs-error: 13px,
    fs-description: 12px,
    fs-heading-max-large:80px,
    fs-heading-large-2: 40px,
    fs-description-large: 24px
);

// color
$colors: (
    1: #D1BA7A,
    2: #F5F5F5,
    3: #140808,
    4: #4082D0,
    5: #CAC5C5,
    6: #5A5A5A,
    7: #929292,
    8: #9C9C9C,
    9:#ACACAC,
    10: #707070,
    11: #AAAAAA,
    12: #BDBDBD,
    13: #DBDBDB,
    14: #00b6ff,
    gold: gold,
    light-gray: #D5D5D5,
    white: #FFFFFF,
    red: #e96f66,
    gray:#392828,
    green: rgb(116, 224, 116),
    brown: #392828,
    up: #0074E4,
    down: #BE241A,
    success :#63A103,
    medium-yellow :rgba(241, 193, 60, 1)
);

$background-colors: (
    dropdown: #2C2C2C,
    container: rgba(255, 255, 255, 0.15),
    modal: #242424,
    gray:#392828,
    green:#2DB253,
    red:#BE241A,
    white: #FFFFFF,
    light-gray: #D5D5D5,
    password-format: #6f6060,
    gray-header : rgba(85, 85, 85, 1),
    light-yellow :rgba(219, 165, 15, 0.1),
    lighter-container: rgba(255, 255, 255, 0.1),
    lighter-gray: rgba(72, 72, 79, 0.8),
    bolder-yellow: rgba(244, 185, 31, 0.2)
);

$border-colors: (
    default: #636363,
    dropdown: rgba(255, 255, 255, 0.2),
    gray:#392828,
);

// height
$height-default: 35px;
$width-default: 130px;

// border-radius
$radius-default: 10px;

// shadow
$shadow-box-default: rgba(0, 0, 0, 0.18) 0px 2px 4px;

// custom bootstrap
// dropdowns
$dropdown-link-active-bg: map-get($map: $colors, $key: 3);
// accordion
$accordion-button-color: map-get($map: $colors, $key: 3);
$accordion-button-bg: map-get($map: $colors, $key: white);
$accordion-button-active-bg: map-get($map: $colors, $key: white);
$accordion-button-active-color: map-get($map: $colors, $key: 3);
$accordion-button-focus-border-color: map-get($map: $colors, $key: 3);
$accordion-button-focus-box-shadow: none;
$accordion-icon-color: map-get($map: $colors, $key: 3);
$accordion-icon-active-color: map-get($map: $colors, $key: 3);
$accordion-border-width: 0px;
$accordion-button-padding-y: 20px;

// form floating
$form-floating-label-transform: translateY(-1.25rem);