.register-screen {
    .modal-content {
        border-radius: 10px;
    }

    /* Ẩn checkbox mặc định */
    input[type="checkbox"] {
        display: none;
    }
  
    /* Tạo thiết kế của checkbox tùy chỉnh bằng pseudo-element ::before */
    input[type="checkbox"] + label::before {
        content: '';
        position: absolute;
        left: -13px;
        bottom: 1px;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 3px solid white; /* Viền màu trắng */
        border-radius: 2px;
        background-color: transparent; /* Màu nền trong suốt */
        margin-left: 10px;
        vertical-align: middle;
        transition: background-color 0.3s, border-color 0.3s; /* Hiệu ứng chuyển đổi */
    }
    
    /* Tạo trạng thái của checkbox đã chọn */
    input[type="checkbox"]:checked + label::before {
        border-color: white; /* Viền màu trắng khi được chọn */
    }
    
    /* Tùy chỉnh màu sắc và kiểu chữ khi checkbox được chọn */
    input[type="checkbox"]:checked + label {
        label {
            margin-left: 13px !important;
        }
    }
}

.ReactModalPortal {
    .ck-read-only {
        border: none !important;
        padding: 0 !important;
        .ck-widget__selection-handle {
            display: none !important;
        }
    }
}

