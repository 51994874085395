.inquiry-screen {
    .wrapper-form {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        //width: 100%;
        .wrapper-form-content {
            .form-input {
                width: 1100px;
                input, textarea {
                    color: white !important;
                    padding-left: 10px !important;
                    
                    background-color: inherit;
                    font-size: 16px;
                    
                    &::after {
                        background-color: inherit !important;
                      }
                    &::placeholder {
                        color: #7E7E7E;
                        font-weight:bolder;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                    border:  1px solid #7E7E7E !important;
                }
                input {
                    
                    min-height: 50px;
                }
                textarea {
                    min-height: 200px;
                }
                
            }
        }
        
    }     
}
@media only screen and (max-width: 1440px) {
    .inquiry-screen {
        .wrapper-form {
            .wrapper-form-content {
                .form-input {
                    width: 950px;
                }
                
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .fs-heading-large {
        font-size: 26px;
    }
    .fs-heading-normal {
        font-size: 18px;
    }
    .fs-heading-small {
        font-size: 16px;
    }
    .inquiry-screen {
        .wrapper-form {
            .wrapper-form-content {
                .form-input {
                    width: 800px;
                }
                
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .inquiry-screen {
        .wrapper-form {
            .wrapper-form-content {
                .form-input {
                    width: 650px;
                }
                
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .inquiry-screen {
        .wrapper-form {
            .wrapper-form-content {
                .form-input {
                    width: 520px;
                }
                
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .inquiry-screen {
        .wrapper-form {
            .wrapper-form-header {
                padding: 0 10px;
            }
            .wrapper-form-content {
                .form-input {
                    width: 400px;
                }
                .btn-form {
                    button {
                        width: 100%!important;
                        font-size: small;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 450px) {
    .inquiry-screen {
        .wrapper-form {
            
            .wrapper-form-content {
                .form-input {
                    width: 350px;
                }
                .btn-form {
                    button {
                        font-size: small;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 392px) {
    .inquiry-screen {
        .wrapper-form {
            
            .wrapper-form-content {
                .form-input {
                    width: 250px;
                }
                .btn-form {
                    button {
                        font-size: small;
                    }
                }
            }
        }
    }
}