@use '../../variables' as css;

.position_btn {
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 17px;
}

.custom-p {
    margin: 0;
    line-height: 17px;
}

.overlay-default {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.option-description {
    border-radius: 6px;
    background-color: #0F1721;
    p {
        font-size: 12px;
    }
}

.holder-screen {
    //position: relative;
    
    .holder-list {
        .holder-item {
            .holder-item-content {
                box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
                position: relative;
                border-radius: 10px;
                width: 100%;
                height: 100%;
                background: url('../../../public/images/holder-bg.png') no-repeat rgba(108, 108, 108, 0.35);
                &.hightlight {
                    box-shadow: 0px 0px 4px 4px rgba(255, 255, 255, 0.45) !important;
                }
                .overlay {
                    @extend .overlay-default;
                    background-color: rgba(108, 108, 108, 0.65);
                    opacity: 0.5; // Độ mờ của lớp
                }
                .open-holder {
                    @extend .overlay-default;
                    background: rgba(209, 186, 122, 0.65);
                    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25); /* Rectangle 24 */
                    border-radius: 10px; 
                }
                .center-btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: transparent;
                    img {
                        width: 75px;
                    }
                    .opacity-item {
                        opacity: 0.65;
                    }
                }
                .lock-content, .add-holder-conent {
                    position: relative;
                    top: 60px;
                    color: white;
                }
                .lock-content.paid {
                    p {
                        color: #D85C54
                    }
                }
                .btn-remove-holder {
                    @extend .position_btn;
                    width: 34px;
                    height: 34px;
                    img {
                        width: 34px;
                    }
                }
                .warning-purchase {
                    @extend .overlay-default;
                    z-index: 1;
                    background-color: rgba(0, 0, 0, 0.65);
                    padding: 60px;
                    .warning-content {
                        border-radius: 18px;
                        background-color: rgba(36, 36, 36, 0.90);
                    }
                }
                .card-select {
                    position: absolute;
                    z-index: 10;
                    right: 17px;
                    top: 14px;
                    /* Style the custom checkbox container */
                    .custom-checkbox {
                        width: 24px;
                        height: 24px;
                        position: relative;
                        cursor: pointer;
                        display: inline-block;
                        vertical-align: middle; /* Align vertically within content */
                    }

                    /* Style the custom checkbox */
                    .styled-checkbox {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }

                    /* Style the custom checkbox border */
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 3px solid #D1BA7A; /* Border color for both checked and unchecked */
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: background-color 0.2s, border-color 0.2s; /* Add transition for smooth color change */
                        svg {
                            display: none;
                            position: absolute;
                            top: 45%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 20px;
                            height: 20px;
                        }
                    }

                    /* Style the custom checkbox when checked */
                    .styled-checkbox:checked + .checkmark {
                        background-color: transparent; /* Background color when checked */
                        border-color: #D1BA7A; /* Border color when checked */
                    }


                    /* Show the tick symbol when checked */
                    .styled-checkbox:checked + .checkmark {
                       svg {
                        display: block;
                       } 
                    }
                } 

                .warning-holder-purchase {
                    position: absolute;
                    top: 14px;
                    font-weight: 700;
                    margin-top: 10px;
                    i {
                        color: #DD2F24 !important;
                        width: 20px;
                        height: 20px;
                        font-size: 20px;
                    }
                    span {
                        color: #DD2F24 !important;
                    }
                }

                .warning-card-holder-purchase {
                    font-weight: 700;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    i {
                        color: #DD2F24 !important;
                        width: 20px;
                        height: 20px;
                        font-size: 20px;
                    }
                    span {
                        color: #DD2F24 !important;
                    }
                }

                .doing-sort-card {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.623);
                    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
                    border-radius: inherit;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
            
                    .select-location-sort-card {
                        border: none;
                        outline: none;
                        box-shadow: none;
                        background-color: #D1BA7A;
                        border-radius: 4px;
                        padding: 0px 24px;
                    }
                }
            
                &.doing-sort-card-active {
                    filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.8));
                    
                    .doing-sort-card {
                        background: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .expiry-date-holder {
        position: absolute;
        bottom: 25px;
        font-size: 16px;
        padding: 7px 14px;
        border: 0.3px solid rgba(255, 255, 255, 1);
        border-radius: 8px;
    }
}


.holder-card {
    width: 100%;
    height: 100%;
    background-color: rgb(72, 70, 70);
    border-radius: inherit;
    position: relative;
    flex-direction: column;
    .holder-card-item {
        width: 100%;
        margin: 0;
        .holder-card-img {
            padding: 0;
            img {
                width: 193px;
                //height: 262px;
                
            }
        } 
        .holder-card-content {
            .card-balance-info {
                .balance-item {
                    p {
                      @extend .custom-p;  
                    }
                }
            }
            .card-item {
                width: 100%;
                margin: 0;
                .card-item-detail {
                    padding: 0;
                    .card-item-icon {
                        width: 35px;
                        height: 35px;
                        border-radius: 5px;
                        background-color: #333;
                        box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.35);
                    }
                    .card-item-info {
                        width: calc(100% - 50px);
                        p {
                        @extend .custom-p
                        }
                    }
                    .up-rate {
                        background-color: map-get($map: css.$colors, $key: up);
                    }
                    .down-rate {
                        background-color: map-get($map: css.$colors, $key: down);
                    }
                    .balance {
                        background-color: map-get($map: css.$colors, $key: 'brown');
                    }
                }
            }
        }
    }

    .disabled-remove-card {
        opacity: 0.25;
        cursor: not-allowed;
    }
}

.exit-sort-card {
    color: #D1BA7A !important;
    text-decoration: underline;
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
}

.holder-card-group-button {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .holder-card-btn {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #D1BA7A;
        color: #ffff !important; 
        border-radius: 100%;
        cursor: pointer;

        &.lock-card-bg, &.sort-card-bg {
            background-color: #575757;
        }
    }
}

.holder-btn-group {
    .holder-btn {
        line-height: 10px;
        border-radius: 4px;
        &.holder-btn-release {
            background-color: #5A5A5A;
            border: 1px solid #D1BA7A;
        }
        &.holder-btn-remove {
            background-color: #878787;
            border: none;
            position: relative;
            .purchased-message {
                position: absolute;
                top: -20px;
                left: 0;
                span {
                    font-size: 12px;
                }
            }

            &.holder-btn-exit-sort-card {
                background-color: #DD2F24;
                border: none;
                outline: none;
                box-shadow: none;
                border-radius: 4px;
                padding: 0px 24px;
                max-width: 302.63px;

                i {
                    font-size: 18px;
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        &.holder-btn-payment {
            border: none;
            background-color: #D1BA7A;
        }
    }

}


//modal
.holder-check {
    position: absolute;
    top: 30px;
    left: -100px;
    width: 100vw;
    height: 95%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(3px);
}
.modal-default {
    position: fixed;
    border-radius: 18px;
    background: rgba(36, 36, 36, 0.98);
    top: 50%; 
    left: 50%; 
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%); 
    z-index: 100;
    width: 714px;
    //box-shadow: 0px 4px 9px 0px rgba(135, 135, 135, 0.25);
    &.modal-paid-member {
        height: 150px;   
    }
    &.modal-select-payment {
        max-height: 400px;
        .modal-select-header {
            height: 60px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        }
        .modal-select-content {
            border-radius: inherit;
            .form-check-input {
                background-color: inherit;
                border-color: #D1BA7A;
                &:focus {
                    box-shadow: none;
                }
                &:checked[type='radio'] {
                    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23D1BA7A%27/%3e%3c/svg%3e ");
                }
            }
            .option-description {
                border-radius: 6px;
                background-color: #0F1721;
                p {
                    font-size: 12px;
                }
            }
        }
    }
}



.notification-popup {
    input[type='checkbox'] {
        width: 50px;
        height: 28px;
    }
    .form-switch {
        .switch-input{
            &:checked {
                background-color: #D1BA7A!important;
                border-color: #D1BA7A;
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='black'/></svg>");
            }
            &:focus {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='black'/></svg>");
            }
        }
    }
    
    p {
        margin-bottom: 0;
    }
    
}
.add-holder {
    width: 500px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.line-height-1 {
    line-height: 1px!important;
}
.modal-footer-group {
    
    gap: 100px !important
}

.purchase-holder-line {
    border-bottom: 0.8px solid #636363;
}

.extend-holder-screen {
    .bg-cs-container {
        .extend-holder-item {
            .extend-holder-option {
                .container-checkbox {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    font-size: 22px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 30px;
                        width: 30px;
                        border: 2px solid map-get($map: css.$colors, $key: 1);
                        border-radius: 5px;
                    }
                    input:checked ~ .checkmark {
                        background-color: map-get($map: css.$colors, $key: 1);
                    }
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 9px;
                        top: 5px;
                        width: 8px;
                        height: 13px;
                        border: 1px solid map-get($map: css.$colors, $key: 3);
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    input:checked ~ .checkmark:after {
                        display: block;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 1600px) {
    .holder-card {
        .holder-card-item {
            .holder-card-img {
                img {
                   
                }
            }
            
            .holder-card-content  {
                padding: 0px 0px 0px 24px;
            }
        }
    }
    
}
@media only screen and (max-width: 1440px) {
    .holder-screen {
        .holder-list {
            .holder-item {
                .holder-item-content {
                    .warning-holder-purchase {
                        flex-direction: column;
                    }
    
                    .warning-card-holder-purchase {
                        flex-direction: column;
                    }
                }
                
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .fs-heading-large {
        font-size: 26px;
    }
    .fs-heading-normal {
        font-size: 18px;
    }
    .fs-heading-small {
        font-size: 16px;
    }
    .holder-screen {
        
    }
    .holder-check {
        left: -50px;
    }
    .holder-card {
        .holder-btn-group {
            span {
                font-size: 14px!important;
            }
            
        }
    }

    .extend-holder-screen {
        flex-direction: column;
        .w-75 {
            width: 100% !important;
        }
        .w-25 {
            width: 100% !important;
        }
    }
}


@media only screen and (max-width: 992px) {
    
    .holder-screen {
        
    }
    .holder-check {
        left: -35px;
        height: 98%;
    }

    .purchase-holder-screen {
        flex-direction: column;
        .w-75 {
            width: 100% !important;
        }
        .w-25 {
            width: 100% !important;
        }
    }

    .extend-holder-screen {
        .pd-lr-20 {
            padding-right: 0;
            .extend-holder-option {
                .container-checkbox, .container-date-expiry {
                    flex: 0 0 25% !important;
                }
                .col-4 {
                    flex-grow: 1;
                    .width-220 {
                        width: 180px !important;
                    }
                }
                .container-price {
                    flex: 0 0 auto;
                    margin-top: 10px;
                    text-align: right !important;
                }
            }
        }
    }
    
}

@media only screen and (max-width: 768px) {
    .holder-screen {
        
    }
    .holder-check {
        left: -20px;
    }
    .modal-default {
        width: 550px!important;
    }

    .purchase-holder-screen {
        .purchase-holder-item {
            display: flex;
            flex-direction: column;
            .row {
                --bs-gutter-x: 0 !important;
                .purchase-holder-info {
                    display: flex;
                    flex-direction: column;
                    .purchase-holder-select {
                        width: 100% !important;
                        select {
                            width: 100% !important;
                        }
                    }
                }
            }

            .purchase-holder-remove {
                padding: 5px !important;
                border-radius: 10px;
                border: 1px solid map-get($map: css.$colors, $key: 1);
            }
        }
    }

    .extend-holder-screen {
        .pd-lr-20 {
            .extend-holder-option {
                .container-date-expiry {
                    display: flex;
                    gap: 2.25rem;
                    flex-grow: 1 !important;
                }
                .col-4 {
                    flex: 0 0 96%;
                    margin-left: 4% !important;
                    margin-top: 10px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 620px) {
    .purchase-holder-screen {
        .purchase-holder-item {
            .purchase-holder-option {
                --bs-gutter-x: 0 !important;
                display: flex;
                flex-direction: column;
                gap: 0.5rem !important;
                .col-8 {
                    width: 100% !important;
                    .col-5 {
                        width: 40% !important;
                    }
                    .col-7 {
                        width: 60% !important;
                    }
                }
                .col-3 {
                    width: 100% !important;
                    text-align: right;
                }
            }
        }
    }

    .extend-holder-screen {
        .extend-holder-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .col-11 {
                .pd-lr-20 {
                    .extend-holder-option {
                        .container-checkbox {
                            flex: 0 0 35% !important;
                        }
                        .container-date-expiry {
                            gap: 1.25rem;
                        }
                    }
                }
            }

            .extend-holder-delete-btn {
                width: 80% !important;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px !important;
                border-radius: 10px;
                border: 1px solid map-get($map: css.$colors, $key: 1);
                margin-top: 10px !important;

                div {
                    margin-top: 0 !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .fs-description {
        font-size: 10px!important;
    }
    .fs-label {
        font-size: 14px!important;
    }
    .holder-screen {
        .holder-list {
            .holder-item {
                .holder-item-content {
                    .btn-remove-holder {
                        position: absolute;
                        top: 14px;
                        right: 7px;
                        width: 30px;
                        height: 30px;
                        img {
                            width: 25px;
                        }
                    }
                }
            }
        }
        
    }
    .holder-card {
        .holder-card-item {
            .holder-card-img {
                img {
                    width: 170px;
                    
                }
            }
            .holder-card-content {
                padding: 0;
                
            }
        }
    }
    .modal-footer-group {
        button:nth-child(1) {
            order: 2;
            width: 100%;
        }
        button:nth-child(2) {
            order: 1;
            width: 100%;
        }
        gap: 10px !important
    }
    .modal-default {
        width: 350px!important;
    }

    .extend-holder-screen {
        .pd-lr-20 {
            .extend-holder-option {
                .container-checkbox {
                    flex: 0 0 30% !important;
                    .fs-heading-normal {
                        font-size: 16px !important;
                        margin-left: 30px !important;
                    }
                    .checkmark {
                        top: 8px !important;
                        left: 0 !important;
                        height: 20px !important;
                        width: 20px !important;
                    }
                    .checkmark:after {
                        left: 5px !important;
                        top: 2px !important;
                        width: 6px !important;
                        height: 11px !important;
                    }
                }
                .container-date-expiry {
                    gap: 0.25rem;
                    justify-content: center !important;
                }
            }
        }
    }
    
}
@media only screen and (max-width: 450px) {
    .holder-screen {
        .holder-list {
            .holder-item {
                .holder-item-content {
                    .warning-purchase {
                        padding: 5px 10px!important;
                        display: block!important;
                        .warning-content {
                            height: 100%;
                        }
                    }

                    .warning-card-purchase {
                        flex-direction: column;
                    }
                }
            }
        }
    }

    .extend-holder-screen {
        .extend-holder-item {
            .col-11 {
                .pd-lr-20 {
                    .extend-holder-option {
                        .container-checkbox {
                            flex-grow: 1 !important;
                        }
                        .container-date-expiry {
                            flex: 0 0 100% !important;
                            margin-top: 10px;
                            justify-content: start !important;
                            margin-left: 4% !important;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 392px) {
    .modal-footer-group {
        gap: 10px !important
    }

    .modal-default {
        width: 280px!important;
    }

    .modal-default {
        width: 300px!important;
    }

    .fs-label {
        font-size: 12px!important;
    }

    .holder-card-group-button {
        right: 7px;
    }

    .select-location-sort-card, .holder-btn-exit-sort-card {
        padding: 0px 10px !important;
    }
}

@media only screen and (max-width: 337px) {
    .holder-card {
        .holder-card-item {
            padding-left: 10px!important;
            padding-right: 10px!important;
        }
    }
}


