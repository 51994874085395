.img-avatar {
    object-fit: cover;
    border-radius: 155px;
}
.account-info-screen {
    height: 100%;
    width: 100%;
    .account-avatar {
        
    }
    .account-info-list-items {
        padding-right: 30px;
        .account-item {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            p:first-child {
                min-width: 180px;
            }
            min-height: 60px;
            p {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                line-height: 30px;
            }
            border-bottom: solid 1px rgba(255, 255, 255, 0.35);
            .edit {
                cursor: pointer;
            }
        }
        .account-item-last {
            border-bottom: solid 2px rgba(255, 255, 255, 0.35);
        }
    }
}
.edit-account-screen {
    .row {
        .col-3 {
            
        }

        .col-8 {
            input {
                font-size: 16px !important;
                font-weight: 500 !important;
            }
            .disabled-style {
                background-color: #141414E5 !important;
            }
        }
    }
}

.setting-drop-address-screen {
   
    //max-height: 600px;
    //overflow: auto!important;
    .drop-address-list {
        max-height: 400px;
        overflow: auto!important;
        .drop-address-item {
            //position: relative;
            margin: 0;
            .drop-item-input {
                width: 100%;
                
            }
            
            .drop-remove {
                cursor: pointer;
            }
        }
    }
    .btn-drop-group {
        gap: 60px!important
    }
    .item-border-bottom {
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);
    }
    .border-gold {
        border: 1px solid #D1BA7A !important;
    }
    .drop-error {
        width: calc(100% - 125px);
    }
}

@media only screen and (max-width: 1440px) {
    .account-info-screen {
        .account-info-list-items {
            .account-item {
                p:first-child {
                    min-width: 140px;
                }
                div:nth-child(3) {
                    justify-content: start!important;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .account-info-screen {
        .account-info-list-items {
            .account-item {
                p:first-child {
                    min-width: 170px;
                }
                div:nth-child(3) {
                    justify-content: end!important;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .account-info-screen {
        .account-info-list-items {
            margin-top: 20px;
            .account-item {
                
            }
            
        }
    }
    .edit-account-screen {
        .row {
            flex-direction: column;
            .col-3 {
                width: 100%;
            }

            .col-8 {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 714px) {
    .account-info-screen {
        .account-info-list-items {
            .account-item {
                p:nth-child(3) {
                    justify-content: start!important;
                }
            }
            .btn-edit-account {
                width: 100%;   
            }
        }
    }
    .setting-drop-address-screen {
        //min-width: 450px;
        // .drop-error {
        //     max-width: 400px;
        // }
    }
}

@media only screen and (max-width: 600px) {
    .account-info-screen {
        .account-info-list-items {
            .account-item {
              
            }
        }
    }
    .edit-username-modal {
        form {
            .d-flex {
                flex-direction: column-reverse;
                button {
                    width: 100%;
                }
            }
        }
    }
    .setting-drop-address-screen {
        //min-width: 450px;
        // .drop-error {
        //     max-width: 300px;
        // }
        .btn-drop-group {
            gap: 40px!important
        }
    }
}

@media only screen and (max-width: 450px) {
    .account-info-screen {
        .account-info-list-items {
            .account-item {
                p:first-child {
                    min-width: 120px;
                }
            }
        }
    }
    .edit-account-screen {
        .row {
            .col-8 {
                .row {
                    .d-flex {
                        flex-direction: column-reverse;
                        align-items: center;
                        gap: 16px !important;
                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 392px) {
    .setting-drop-address-screen {
        .drop-label {
            font-size: 12px;
        }
        .drop-error {
            width: calc(100% - 106px);
        }
        .btn-drop-group {
            gap: 5px!important;
            button {
                width: 90% !important;
                
            }
        }
    }
}