@use "../../variables" as css;

/* Custom styles for submenu animation */
@keyframes custom-fade-in {
    from {
        opacity: 0;
        transform: translateY(-10px);
        /* Điều chỉnh hướng di chuyển */
    }

    to {
        opacity: 1;
        transform: translateY(0);
        /* Điều chỉnh hướng di chuyển */
    }
}

.wrapper {

    &.wrapper-only-background {
        .wrapper-container {
            width: 100%;
            min-height: 100vh;
            background: linear-gradient(113.49deg, #D1BA7A -30.3%, #111111 58.12%);
            position: relative;

            .card-group {
                position: absolute;
                left: 0;
                bottom: 10vh;
                max-height: 95vh;
                max-width: 95vw;
            }

            .wrapper-content {
                width: 100%;
                padding: 100px;
                display: flex;
                align-items: center;
                justify-content: end;

                .wrapper-form {
                    width: 530px;
                    height: calc(100vh - 200px);
                    min-height: 700px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: css.$radius-default;
                    padding: 30px;
                    position: relative;

                    .form-bottom {
                        position: absolute;
                        width: 100%;
                        padding: 0px 30px;
                        left: 0px;
                        bottom: 50px;
                    }
                }
            }
        }
    }

    &.wrapper-body {
        .wrapper-container {
            width: 100%;
            min-height: 100vh;
            background-color: #0E0E10;
            padding: 50px 100px;
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                width: 717px;
                height: 691px;
                left: -256px;
                top: -272px;

                background: #D1BA7A;
                opacity: 0.3;
                filter: blur(200px);
                z-index: 0;
            }

            &::after {
                content: '';
                position: absolute;
                width: 609px;
                height: 307px;
                left: -304px;
                bottom: -400px;
                background: #D1BA7A;
                filter: blur(255px);
                z-index: 0;
            }

            .wrapper-header,
            .wrapper-content {
                position: relative;
            }

            .wrapper-header {

                * {
                    color: white;
                }

                .header-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .header-nav-dropdown {
                        display: none;
                    }

                    .header-nav {
                        display: flex;
                        align-self: flex-end;
                        align-items: center;
                        gap: 80px;

                        a {
                            transition: 0.3s all;

                            &:hover {
                                color: map-get($map: css.$colors, $key: 1) !important;
                            }
                        }
                    }

                    .header-avatar {
                        .avatar {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            i {
                                font-size: 20px;
                            }
                        }

                        img {
                            width: 42px;
                            height: 42px;
                            border-radius: 100%;
                        }

                        .dropdown-menu {

                            li {
                                position: relative;
                            }

                            >li[role=button] {
                                &:hover {
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 6px;
                                        height: 100%;
                                        background-color: map-get($map: css.$colors, $key: 1);
                                    }
                                }

                                &:last-child {
                                    &:hover {
                                        &::before {
                                            content: "";
                                            border-bottom-left-radius: 10px;
                                        }
                                    }
                                }
                            }

                            .lst-user {
                                padding: 0 !important;
                                max-height: 200px;
                                overflow: overlay;

                                .lst-user-list {
                                    list-style-type: none;
                                    padding: 0 !important;

                                    li {
                                        padding-left: 25px;
                                        border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;

                                        &:last-child {
                                            border-bottom: none !important;
                                        }

                                        &:hover {
                                            &::before {
                                                content: "";
                                                position: absolute;
                                                left: 0;
                                                top: 0;
                                                width: 6px;
                                                height: 100%;
                                                background-color: map-get($map: css.$colors, $key: 1);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .wrapper-content {
                padding: 100px 0px 150px;

                &.wrapper-content-white {
                    * {
                        color: white;
                    }
                }
            }

            .wrapper-footer {
                position: absolute;
                left: 0px;
                padding: 0px 100px;
                bottom: 50px;
                width: 100%;

                * {
                    color: white;
                }

                .btn-back-to-top {
                    position: fixed;
                    right: 20px;
                    bottom: 90px;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    z-index: 5;
                }

                .footer-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .footer-nav {
                        a {
                            color: map-get($map: css.$colors, $key: 1) !important;
                            transition: 0.3s all;

                            &:hover {
                                color: map-get($map: css.$colors, $key: white) !important;
                            }

                            &::before {
                                content: '|';
                                position: relative;
                                top: -1px;
                                color: map-get($map: css.$colors, $key: 1) !important;
                                padding: 0px 10px;
                            }

                            &:first-child {
                                &::before {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.container-with-sidebar {
    gap: 30px;

    .side-bar {
        position: relative;
        width: 25%;

        .border-bottom-opacity {
            border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
        }

        .side-bar-collapse {
            position: absolute;
            top: -35px;
            display: none;

            i {
                font-size: 1.5rem;
            }
        }

        .list-item-side-bar {

            // .list-item-side-bar-items div :last-child {
            //     border-bottom: none !important;
            // }

            .sidebar-children-item-list :last-child {
                border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
            }

            .list-item-side-bar-item:hover {
                background-color: black;
                color: #D1BA7A
            }

            .list-item-side-bar-item.active {
                background-color: black;

            }

            .list-item-side-bar-item.active .active-text {
                color: #D1BA7A
            }
        }
    }

    .container-with-sidebar-content {
        width: 75%;
    }
}

.fade-in-custom {
    animation: custom-fade-in 0.4s ease-in-out forwards;
    /* Điều chỉnh thời gian và loại animation */
}

.container-banner {
    .banner-header {
        width: 85%;
    }

    .banner-image {
        position: relative;

        .elip-bright {
            position: absolute;
            width: 80%;
            height: 50%;
            top: 150px;
            left: 150px;
            background: #D1BA7A;
            opacity: 0.3;
            filter: blur(200px);
            z-index: 1
        }
    }
}



@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1300px) {
    .wrapper {
        &.wrapper-body {
            .wrapper-container {

                .wrapper-header {
                    .header-container {
                        .header-nav {
                            gap: 60px;
                        }
                    }
                }

            }
        }
    }

    .container-with-sidebar {
        flex-direction: column;

        .side-bar {
            width: 100%;

            .list-item-side-bar-items {
                margin-top: 10px !important;
                // display: flex;
                // align-items: center;
                // justify-content: space-between;

                // .list-item-side-bar-item {
                //     width: 100%;
                // }
            }
        }

        .container-with-sidebar-content {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .wrapper {

        &.wrapper-only-background {
            .wrapper-container {

                .card-group {
                    display: none;
                }

                .wrapper-content {
                    padding: 100px 20px;
                    justify-content: center;
                }
            }
        }

        &.wrapper-body {
            .wrapper-container {
                padding: 50px;

                .wrapper-footer {
                    padding: 0px 50px;

                    .btn-back-to-top {
                        right: 10px;
                    }

                    .footer-container {
                        flex-direction: column-reverse;
                        align-items: start;
                        gap: 5px;
                    }
                }
            }
        }
    }




}

@media only screen and (max-width: 992px) {
    .wrapper {
        &.wrapper-body {
            .wrapper-container {
                padding: 50px 35px;

                &::before {
                    left: -300px;
                    top: -350px;
                }

                &::after {
                    left: -304px;
                    bottom: -500px;
                }



                .wrapper-header {
                    .header-container {
                        .header-nav {
                            display: none;
                        }

                        .header-nav-dropdown {
                            display: block;

                            .header-menu {
                                font-size: 35px;
                            }
                        }
                    }
                }

                .wrapper-footer {
                    padding: 0px 35px;

                    .btn-back-to-top {
                        right: 5px;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 768px) {
    .wrapper {
        &.wrapper-body {
            .wrapper-container {
                padding: 50px 20px;

                .wrapper-header {
                    .header-container {
                        // .header-nav-dropdown {
                        //     display: block;

                        //     .header-menu {
                        //         font-size: 35px;
                        //     }
                        // }

                        .header-logo {
                            width: 120px;
                        }

                        // .header-nav {
                        //     display: none;
                        // }

                        .header-avatar {}
                    }
                }

                .wrapper-footer {
                    padding: 0px 20px;

                    * {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .container-banner {
        .banner-header {
            font-size: 60px !important;
        }
    }
}

@media only screen and (max-width: 576px) {

    .wrapper {
        &.wrapper-only-background {
            .wrapper-container {
                .wrapper-content {
                    .wrapper-form {
                        padding: 30px 15px;
                    }
                }
            }
        }

        &.wrapper-body {
            .wrapper-container {

                &::before {
                    left: -350px;
                    top: -500px;
                }

                &::after {
                    left: -400px;
                    bottom: -600px;
                }

                .wrapper-header {
                    .header-container {
                        .header-nav-dropdown {
                            display: block;

                            .header-menu {
                                font-size: 35px;
                            }
                        }

                        .header-logo {
                            width: 100px;
                        }
                    }
                }

                .wrapper-footer {
                    * {
                        font-size: 14px;
                    }

                    .footer-nav {
                        display: flex;
                        gap: 10px;
                        flex-wrap: wrap;

                        a {
                            &::before {
                                content: none !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .container-banner {
        .banner-header {
            font-size: 40px !important;
        }

        .banner-image {
            .elip-bright {
                top: 50px;
                left: 50px;
            }

            img {
                width: 350px;
            }
        }
    }

    .confirm-switch-account {
        .footer-button {
            flex-direction: column-reverse;

            button {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 400px) {

    .modal-account {
        .footer-button {
            flex-direction: column-reverse;

            button {
                width: 100%;
            }
        }
    }
}