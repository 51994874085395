.change-info-screen {
    .row {
        .col-3 {
            .header-nav-dropdown {
                display: inline;
                .info-icon{
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    margin-left: 5px;
                    color: #C1C1C1 !important;
                    background-color: #474747 !important;
                    border-radius: 50%;
                    position: relative;
                }
                .dropdown-menu {
                    width: fit-content !important;
                    .dropdown-item {
                        color: #929292 !important;
                        border: none;
                        font-size: 14px;
                        padding: 0;
                    }
                    .dropdown-item:hover {
                        background-color: #2c2c2c;
                    }
                }
            }
        }
        .col-8 {
            input {
                font-size: 16px !important;
                font-weight: 500 !important;
            }
        }
    }
}

.setPassword-screen {
    .mg-t-35 {
        .title {
            .header-nav-dropdown {
                display: inline;
                .info-icon{
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    margin-left: 5px;
                    color: #D1BA7A !important;
                    background-color: #474747 !important;
                    border-radius: 50%;
                    position: relative;
                    border: 1px solid #D1BA7A;
                }
                .dropdown-menu {
                    width: fit-content !important;
                    .dropdown-item {
                        color: #929292 !important;
                        border: none;
                        font-size: 14px;
                        padding: 0;
                    }
                    .dropdown-item:hover {
                        background-color: #2c2c2c;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .change-info-screen {
        label {
            width: 33.33%;
        }

        input {
            width: 58.33%;
        }

        .col-3 {
            width: 33.33%;
        }

        .col-8 {
            width: 58.33%
        }
    }
}

@media only screen and (max-width: 1200px) {
    .change-info-screen {
        label {
            width: 25%;
        }

        input {
            width: 66.67%;
        }

        .col-3 {
            width: 25%;
        }

        .col-8 {
            width: 66.67%
        }
    }
}

@media only screen and (max-width: 992px) {
    .change-info-screen {
        label {
            width: 35%;
        }
        input {
            width: 60%;
        }
        .col-3 {
            width: 35%;
        }

        .col-8 {
            width: 60%
        }
    }
}

@media only screen and (max-width: 714px) {
    .change-info-screen {
        min-width: none;
        .row {
            flex-direction: column;
            label {
                width: 100%;
            }
            input {
                width: 100%;
            }
            .col-8 {
                width: 100%;
            }
            input {
                margin-top: 5px !important;
            }
        }
    }
}

@media only screen and (max-width: 576px) {

}

@media only screen and (max-width: 450px) {
    .change-info-screen {
        .row {
            margin-top: 32px !important;
            .col-8 {
                flex-direction: column-reverse;
                align-items: center;
                gap: 16px !important;
                .btn-bg-cs-1 {
                    width: 100%;
                }
                .btn-cs-1 {
                    width: 100%;
                }
            }
        }
    }
}