.list-card-screen {
    .list-card-item {
        width: 100%;
        height: 100%;
        background-color: rgba(85, 85, 85, 1);
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 4px;
    }

    .col-4-10 {
        width: calc((100% / 3) - 10px);
        // margin: 10px;
    }

    .card-item {
        .card-item-icon {
            border-radius: 5px;
            background: var(--gray, #333);
            box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.35);
        }

        // .card-item-info {
        //     p {
        //         @extend .custom-p
        //     }

        //     .info-content {
        //         font-weight: 600;
        //     }
        // }
    }

}

.list-purchase-history-screen {
    .list-card-item-purchase {
        width: 100%;
        height: 100%;
        background-color: rgba(85, 85, 85, 1);
        border-radius: 6px;
        display: flex;
        padding: 20px;
    }

    .input-custom {
        height: 45px !important;
    }
}

.list-card-buy-screen {
    .list-card-buy-item {
        width: 100%;
        height: 100%;
        background-color: rgba(85, 85, 85, 1);
        border-radius: 6px;
        display: flex;
        padding: 20px;
    }

    .card-item {
        .card-item-icon {
            border-radius: 5px;
            background: var(--gray, #333);
            box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.35);
        }
    }

    .text-setting {
        color: rgba(45, 178, 83, 1) !important
    }
}

.overlay-default {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.holder-item-content {
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: url('../../../public/images/holder-bg.png') no-repeat rgba(108, 108, 108, 0.35);

    .overlay {
        @extend .overlay-default;
        background-color: rgba(108, 108, 108, 0.65);
        opacity: 0.5; // Độ mờ của lớp
    }
}

.history-purchase-card {
    position: relative;

    .notification {
        position: absolute;
        top: 14px;
        right: 17px;
    }

    .detail-navigate {
        position: absolute;
        bottom: 14px;
        right: 17px;
    }

    .border-bottom {
        border-bottom: 1px solid !important;
    }

    .collapse {
        position: absolute;
        bottom: 8px;
    }
}

.border-cs-gray {
    border-color: #392828, ;
}

.select-custom {
    background-color: inherit !important;

    option {
        background-color: #0E0E10;

        &:checked,
        &:hover {
            background-color: #D1BA7A !important;
        }
    }
}

.select-custom:focus {
    border-color: inherit !important;
}

@media only screen and (max-width: 1440px) {
    .list-card-screen {
        .list-item {
            .list-item-wrapper {
                width: 50% !important;
            }
        }
    }

    .purchase-holder-detail-screen {
        .list-item {
            .purchase-item {
                width: 49% !important;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {

    .screen-card-title {
        font-size: 60px !important;
    }

    .screen-card-image {
        justify-content: center;
        width: 100%;
    }


    .fs-heading-large {
        font-size: 26px;
    }

    .fs-heading-normal {
        font-size: 18px;
    }

    .fs-heading-small {
        font-size: 16px;
    }

    .payment-screen {
        .list-card-list-select {
            label {
                width: 100% !important;
                margin-bottom: 10px !important;
            }

            .select-custom {
                width: 100% !important;
            }
        }
    }

}

@media only screen and (max-width: 992px) {
    .list-purchase-history-screen {
        .list-item {
            .max-width-custom {
                flex-direction: column;
            }

            .min-width-custom {
                min-width: 175px;
                margin-bottom: 20px !important;
                width: 50% !important;
            }

            .margin-item-0 {
                margin: 0 !important;
            }
        }
    }

    // .subcription-history-screen {
    //     .subcription-history-card {
    //         width: 100% !important;
    //     }
    // }

    .screen-card-title {
        font-size: 50px !important;
        text-align: center;
    }

    .screen-card-label {
        text-align: center;
    }
}

@media only screen and (max-width: 835px) {
    .list-card-screen {
        .list-item {
            .list-item-wrapper {
                width: 100% !important;
            }
        }
    }

    .credit-card-screen {
        .list-item {
            .list-item-wrapper {
                width: 50% !important;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .list-purchase-history-screen {
        .list-card-item-purchase {
            // flex-direction: column;
        }

        .max-width-custom {
            // flex-direction: column;
            max-width: 100%;
        }

        // .min-width-custom {
        //     min-width: 150px !important;
        //     margin-bottom: 10px !important;
        // }
    }

    .list-card-screen {
        .screen-card-image {
            justify-content: center !important
        }

        .card-list-image {
            width: 100%;
        }
    }

    .screen-card-title {
        font-size: 60px !important;
    }

    .purchase-holder-detail-screen {
        .purchase-holder-detail-info {
            .row.mg-t-20 {
                display: flex;
                flex-direction: column;
                margin: 0 !important;
                .col-6 {
                    width: 100% !important;
                    margin-top: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 670px) {
    .list-card-buy-screen {
        .list-item {
            .min-width-custom {
                width: 100% !important;
            }

            .margin-item-0 {
                margin: 0 !important;
            }
        }
    }

}

@media only screen and (max-width: 638px) {
    .list-card-screen {
        .list-card-list-select {
            gap: 40px !important;

            .select-custom {
                width: 200px !important;
            }
        }

        .list-card-list-select-item {
            label {
                margin-bottom: 10px !important;
            }

            width: 100% !important;

            .select-custom {
                width: 100% !important;
            }
        }
    }

    .purchase-holder-detail-screen {
        .list-item {
            .purchase-holder-item {
                .purchase-item {
                    width: 100% !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .credit-card-screen {
        .list-item {
            .list-item-wrapper {
                width: 100% !important;
            }
        }
    }

    .list-card-buy-screen {
        .list-item {
            .min-width-custom {
                width: 50% !important;
            }

            .margin-item-0 {
                margin: 0 !important;
            }
        }
    }

    .list-purchase-history-screen {
        .list-card-item-purchase {
            flex-direction: column;
        }

        .max-width-custom {
            // flex-direction: column;
            max-width: 100% !important;
        }

        // .min-width-custom {
        //     min-width: 150px !important;
        //     margin-bottom: 10px !important;
        // }
    }

    .list-card-screen {
        .list-card-item {
            padding: 20px;

        }

        .screen-card-title {
            font-size: 40px !important;
        }
    }


}

@media only screen and (max-width: 520px) {
    .list-card-buy-screen {
        .list-item {
            .min-width-custom {
                width: 100% !important;
            }

            .margin-item-0 {
                margin: 0 !important;
            }
        }
    }




}

@media only screen and (max-width: 470px) {
    .list-purchase-history-screen {
        .list-card-item-purchase {
            flex-direction: column;
        }

        .list-card-item-purchase-option {
            justify-content: center !important;
        }

        .max-width-custom {
            // flex-direction: column;
            max-width: 100% !important;
        }

        .card-purchase-detail-last {
            width: 100% !important;
        }

        .content-center {
            justify-content: center !important;
        }

        .heading {
            font-size: 22px;
        }
    }

    .list-purchase-history-responsive {
        .card-detail-image {
            margin-top: 20px;
            justify-content: center;
        }

        .history-purchase-card-label {
            margin-top: 20px;
            width: 100% !important;
            text-align: center;
        }

        .history-purchase-card-label-item {
            flex-wrap: wrap;

            .history-purchase-card-label-width {
                width: 120px;
            }
        }

        .history-purchase-card-label-item-collapse {
            justify-content: start !important;

            .history-purchase-card-label-width {
                width: 120px;
            }

            // .label-width{
            //     wi
            // }

        }

    }

    .list-card-screen {
        .list-card-list-select-item {
            width: 100% !important;

            .select-custom {
                width: 100% !important;
            }
        }

        .list-card-item {

            .card-detail-image,
            .card-detail-label {
                width: 100%;
            }
        }
    }

    .subcription-history-screen {
        .history-purchase-card-label-width {
            width: 50px !important;
        }
    }

    .card-buy-detail {
        .card-buy-detail-item {
            .card-buy-detail-item-label {
                width: 100% !important;
                margin-bottom: 5px !important;

            }

            width: 50% !important;
            display: block !important;
        }
    }
}

@media only screen and (max-width: 420px) {
    .subcription-history-screen {

        .history-purchase-card-label-item {
            .subcription-label-width {
                width: 100% !important;
            }

            // flex-direction: column !important;

        }
    }

    .card-buy-history {
        .history-purchase-card-label-width {
            width: 100% !important;
        }
    }

    .list-purchase-history-screen {
        .history-purchase-card-label-width {
            width: 100% !important;
        }
    }
}

@media only screen and (max-width: 450px) {
    .card-buy-detail {

        .card-buy-detail-item {
            width: 100% !important;
        }
    }
}

.col-6-select {
    width: calc(50% - 80px);
}

.col-10-custom {
    width: calc(83.33333333% - 10px);
}