@use "../../variables" as css;

.ReactModal__Overlay {
    z-index: 7000;
    background-color: rgba(128, 128, 128, 0.692) !important;
}

.ReactModal__Content {
    border: none !important;
    background-color: transparent !important;
    padding: 0px !important;
    max-width: 90%;
    max-height: 90%;
    overflow: visible !important;
}

.modal-content {
    border: none;
    background-color: map-get($map: css.$background-colors, $key: modal) !important;
    color: map-get($map: css.$colors, $key: white) !important;
    min-width: 550px;
    overflow: visible !important;

    .color-original {
        color: initial !important;
    }

    .modal-header {
        border-bottom: none !important;
        padding: 15px !important;

        .modal-title {
            margin: 0;
            line-height: 1.42857143;
        }

        .btn-close {
            text-shadow: 0 1px 0 #fff;
            opacity: 1;

            &:focus:active {
                outline: none !important;
                box-shadow: none;
            }
        }
    }

    .cancel-button {
        float: left;
    }

    h4 {
        font-size: 17px;
    }
}

.modal-footer {
    display: flex;
    justify-content: space-around !important;
}

.fa-sort-desc,
.fa-unsorted,
.fa-sort-asc {
    padding-left: 10px;
    color: #3c8dbc;
}

.modal-auto {
    width: auto;
}



@media only screen and (max-width: 600px) {
    .modal-content {
        min-width: 400px !important;
    }
}

@media only screen and (max-width: 480px) {
    .ReactModal__Content {
        width: 90%;
    }
    .modal-content {
        min-width: 280px !important;

        .modal-header {
            padding: 15px !important;
        }
    }
}