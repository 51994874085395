@media only screen and (max-width: 1440px) {
    
}

@media only screen and (max-width: 1200px) {
    .fs-heading-large {
        font-size: 26px;
    }
    .fs-heading-normal {
        font-size: 18px;
    }
    .fs-heading-small {
        font-size: 16px;
    }
}

@media only screen and (max-width: 992px) {
    
}

@media only screen and (max-width: 768px) {
    
}

@media only screen and (max-width: 576px) {
    
}