@use './variables' as css;

// all
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: break-word;
  // font-family: 'Meiryo UI', sans-serif;
}

html,
body {
  .daterangepicker {
    z-index: 555;
  }
}

body,
#root,
.wrapper {
  min-height: 100vh !important;
  bottom: 0;
}

// link
a {
  color: map-get($map: css.$colors, $key: 4) !important;
  text-decoration: none !important;
}

// input
input:not([type='checkbox']):not([type='radio']),
textarea,
select {
  border: 0.5px solid map-get($map: css.$colors, $key: light-gray);
  border-radius: 5px;
  font-size: map-get($map: css.$fs, $key: fs-input);
  padding: 5px;
  outline: none;

  &:focus {
    border-color: map-get($map: css.$colors, $key: 3);
  }
}

input.input-type-1 {
  background-color: inherit;
  border: 1px solid #737373 !important;
}

input.input-type-2 {
  background-color: rgba(25, 30, 36, 0.9);
  border: none !important;
}

input.reset-auto-fill {

  // remove auto fill
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    transition: background-color 9999s ease-in-out 0s;
  }
}

input:not([type='checkbox']):not([type='radio']),
select {
  height: css.$height-default;
}

.input-floating {
  input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  }

  label {
    font-size: map-get($map: css.$fs, $key: fs-label);
    font-weight: 400 !important;
  }

  input,
  label {
    color: white !important;
    padding-left: 0px !important;
    background-color: inherit !important;

    &::after {
      background-color: inherit !important;
    }
  }

  .form-control {
    min-height: 45px !important;
  }

  // remove auto fill
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    transition: background-color 9999s ease-in-out 0s;
  }
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}

// css dropdown
.dropdown-cs-menu {
  border-radius: css.$radius-default !important;
  background-color: map-get($map: css.$background-colors, $key: dropdown) !important;
  padding: 0px !important;
  width: 280px !important;
  top: 10px !important;

  * {
    color: white !important;
  }

  li {
    padding: 15px 20px;

    a {
      padding: 0px !important;
    }

    &.is-hover:hover {
      cursor: pointer;
      background-color: map-get($map: css.$background-colors, $key: container);
    }
  }

  >li {
    border-top: 1px solid map-get($map: css.$border-colors, $key: dropdown);

    &:first-child {
      border-top: none;
    }
  }

  &.dropdown-cs-menu-left {
    &::before {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      left: 10px;
      top: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid map-get($map: css.$background-colors, $key: dropdown);
    }
  }

  &.dropdown-cs-menu-right {
    &::before {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      right: 10px;
      top: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid map-get($map: css.$background-colors, $key: dropdown);
    }
  }
}

// button 
button {
  height: css.$height-default;
  border-radius: css.$radius-default;
}

// border-radius
.radius-default {
  border-radius: css.$radius-default !important;
}

// shadow
.shadow-box {
  box-shadow: css.$shadow-box-default;
}

// icon as button
.input-with-icon {
  padding-right: 35px !important;
}

.btn-icon-input {
  width: fit-content;
  border: none;
  background-color: inherit;
  font-size: 18px;
  height: 30px;
  float: right;
  position: relative;
  margin-right: 10px;
  margin-top: -32.5px;
  z-index: 2;
  cursor: pointer;
}

// css hover
.hover-underline:hover {
  text-decoration: underline !important;
}

//css cusor
.cursor-pointer {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

/* Custom styles for submenu animation */
@keyframes custom-fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
    /* Điều chỉnh hướng di chuyển */
  }

  to {
    opacity: 1;
    transform: translateY(0);
    /* Điều chỉnh hướng di chuyển */
  }
}


// font-family
@each $f,
$value in css.$font-weight {
  .font-#{$f} {
    font-weight: $value;
  }

  .font-#{$f}-italic {
    font-weight: $value;
    font-style: italic;
  }
}

// font size
@each $f,
$value in css.$fs {
  .#{$f} {
    font-size: $value;
  }
}

// form
form {
  label {
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }
  }
}

// label field required
.field-required::after {
  content: " *";
  color: red;
}

// class col
.extra-col {
  position: relative;
  width: 100%;
}

.col-0-5 {
  @extend .extra-col;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}

.col-1-5 {
  @extend .extra-col;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-2-5 {
  @extend .extra-col;
  flex: 0 0 20.833333325%;
  max-width: 20.833333325%;
}

.col-3-5 {
  @extend .extra-col;
  flex: 0 0 29.166666655%;
  max-width: 29.166666655%;
}

.col-4-5 {
  @extend .extra-col;
  flex: 0 0 37.499999985%;
  max-width: 37.499999985%;
}

.col-5-5 {
  @extend .extra-col;
  flex: 0 0 45.833333315%;
  max-width: 45.833333315%;
}

.col-6-5 {
  @extend .extra-col;
  flex: 0 0 54.166666645%;
  max-width: 54.166666645%;
}

.col-7-5 {
  @extend .extra-col;
  flex: 0 0 62.499999975%;
  max-width: 62.499999975%;
}

.col-8-5 {
  @extend .extra-col;
  flex: 0 0 70.833333305%;
  max-width: 70.833333305%;
}

.col-9-5 {
  @extend .extra-col;
  flex: 0 0 79.166666635%;
  max-width: 79.166666635%;
}

.col-10-5 {
  @extend .extra-col;
  flex: 0 0 87.499999965%;
  max-width: 87.499999965%;
}

.col-11-5 {
  @extend .extra-col;
  flex: 0 0 95.8333333%;
  max-width: 95.8333333%;
}


// css common
@each $color,
$value in css.$colors {
  .text-color-cs-#{$color} {
    color: $value !important;
  }

  .btn-cs-#{$color} {
    background-color: inherit;
    color: $value !important;
    border: 1px solid $value !important;
  }

  .btn-bg-cs-#{$color} {
    background-color: $value !important;
    color: map-get($map: css.$colors, $key: white) !important;
    border: 1px solid $value !important;
  }

  @each $bd-color, $bd-value in css.$colors {
    .btn-bg-cs-#{$color}-bd-cs-#{$bd-color} {
      background-color: $value !important;
      border: 1px solid $bd-value !important;
      color: map-get($map: css.$colors, $key: white) !important;
    }
  }
}

@each $color,
$value in css.$background-colors {
  .bg-cs-#{$color} {
    background-color: $value !important;
  }
}

@for $i from 0 through 100 {

  .fs-cs-#{$i} {
    font-size: #{$i}px !important;

    * {
      font-size: #{$i}px !important;
    }
  }

  .border-radius-#{$i} {
    border-radius: #{$i}px !important;
  }

  .border-radius-bottom-#{$i} {
    border-bottom-right-radius: #{$i}px !important;
    border-bottom-left-radius: #{$i}px !important;
  }

  .mg-#{$i} {
    margin: #{$i}px !important;
  }

  .mg-t-#{$i} {
    margin-top: #{$i}px !important;
  }

  .mg-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .mg-l-#{$i} {
    margin-left: #{$i}px !important;
  }

  .mg-r-#{$i} {
    margin-right: #{$i}px !important;
  }

  .mg-tb-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }

  .mg-lr-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }

  .pd-#{$i} {
    padding: #{$i}px !important;
  }

  .pd-t-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pd-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .pd-l-#{$i} {
    padding-left: #{$i}px !important;
  }

  .pd-r-#{$i} {
    padding-right: #{$i}px !important;
  }

  .pd-tb-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .pd-lr-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }

  .flex-gap-#{$i} {
    gap: #{$i}px !important;
  }
}

$max: 1200;
$step: 5;

@for $i from 0 through calc($max/$step) {
  $value: $i * $step;

  .min-width-#{$value} {
    min-width: #{$value}px;
  }

  .max-width-#{$value} {
    max-width: #{$value}px;
  }

  .width-#{$value} {
    width: #{$value}px;
  }

  .min-height-#{$value} {
    min-height: #{$value}px;
  }

  .max-height-#{$value} {
    max-height: #{$value}px;
  }

  .height-#{$value} {
    height: #{$value}px;
  }
}

// css scroll
/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: map-get($map: css.$colors, $key: white);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($map: css.$colors, $key: 3);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get($map: css.$colors, $key: 3);
}

// infinite loading
.infinite-scroll-component {
  overflow: hidden !important;
}