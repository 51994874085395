@use '../../variables' as css;

.react-datepicker-wrapper {
    .btn-clear-input {
        margin-right: 35px;
        margin-top: -33px;
        width: 25px;
        text-align: center;
    }
}

.react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
        input {
            height: 40px !important;
        }
    }
}

.react-datepicker-custom {
    background-color: map-get($map: css.$colors, $key: white) !important;
    border: 1px solid #D5D5D5 !important;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
    border-radius: 5px !important;

    * {
        color: rgb(132, 144, 149) !important;
    }

    .react-datepicker__header {
        background-color: map-get($map: css.$colors, $key: white);
        border-bottom: none;

        .react-datepicker-header-custom {
            select {
                border: none;
            }
        }
    }

    .react-datepicker__day {

        margin: 0px !important;

        span {
            display: inline-block;
            width: 100%;
            height: 26px;
            border-radius: 100px;
            border: 1px solid transparent;
            position: relative;
        }

        &:hover {
            border-radius: 0px;
            background-color: map-get($map: css.$colors, $key: white) !important;
    
            span {
                border: 1px solid rgb(0, 119, 182);
            }
        }
    }
    
    .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
        background-color: map-get($map: css.$colors, $key: white) !important;

        span {
            color: rgba(255, 255, 255, 0.85) !important;
            background-color: rgb(0, 119, 182);

            &::after {
                content: '';
                position: absolute;
                bottom: 3px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 18px;
                height: 1.5px;
                border-radius: 2px;
                background-color: map-get($map: css.$colors, $key: white) !important;
            }
        }
    }
    
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        color: rgba(255, 255, 255, 0.85) !important;
        background-color: rgb(0, 119, 182);
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: 44.58px;
        height: 36px;

        span {
            height: 26px;
            margin: auto;
        }
    }

    .react-datepicker__day {
        padding: 5px 0px;
    }

    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
        background-color: rgba(248, 248, 248, 0.75) !important;
        cursor: not-allowed;

        &:hover {
            border-radius: 0px;
            background-color: rgba(248, 248, 248, 0.75) !important;
    
            span {
                border: 1px solid transparent;
            }
        }
    }
}

.react-datepicker__triangle {
    display: none;
}

